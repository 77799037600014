export const authentication = {
    isLoggedIn:false,
    onAuthentication(data:any){
        const passenger = JSON.stringify(data)
        localStorage.setItem("webuser",passenger)

        this.isLoggedIn=true
    },

    onAuthenticationCorporate(data:any){
        const corporate = JSON.stringify(data)
        localStorage.setItem("corporate",corporate)
    },
    getLoginStatus(){
        if(localStorage.getItem("webuser")){
        return true;
        }
        else{
            return false;
        }
    },

    getPassengerUser(){
        const passenger = JSON.parse(localStorage.getItem("webuser") as string)
        return passenger

    },

    getCorporateUser(){
        const corporate = JSON.parse(localStorage.getItem("corporate") as string)
        return corporate

    },

    PassengerLogout(){
        localStorage.clear()
        this.isLoggedIn=false
        return true

    }
}