import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { authentication } from '../../service/auth.service';

export function PassengerRoute(props:any){
    return(
        <Route path ={props.path} render={data=>props.isPassenger?(
            <props.component {...data}></props.component>):
            (<Redirect to = {{pathname:'/'}}></Redirect>)}></Route>
    )
}