import React from 'react';
import { AboutContent } from "./sections/Content"
import { Footer } from '../footer/Footer';
 
export function About(){
    return(  
        <>
            <AboutContent/>
            <Footer/>
        </>
    )
} 