import Axios from 'axios';

export const ApiService = Axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  headers: { 'Content-Type': 'application/json' },
}) 

export const PaxApiService = Axios.create({
  baseURL: `${process.env.REACT_APP_PAX_API_URL}/v1`,
  headers: { 'Content-Type': 'application/json' },
})