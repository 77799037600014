import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { authentication } from '../../service/auth.service';

export function PublicRoute(props:any){
    useEffect(()=>{
    },[])
    return(
        <Route path ={props.path} render={data=>(!props.isCorporate&&!props.isPassenger)?(
            <props.component {...data}></props.component>):
            (<Redirect to = {{pathname:'/'}}></Redirect>)}></Route>)
}